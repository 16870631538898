import tokens from '@veneer/tokens'
import styled from 'styled-components'
import { smartQueries } from '../../../../utils/device/device'

export const StyledOrderHeader = styled.div`
  font-family: ${tokens.fontFamilyRegular};
  padding: 16px 16px 10px 12px;
  ${smartQueries.mobileXS(`
    margin-bottom: 50px;
  `)}

  .product-logo {
    margin-bottom: 30px;
    width: 105px;
    height: 29.34px;
  }

  p {
    margin-top: 0;
    margin-bottom: 4px;
    color: ${tokens.colorGray7};
    font-size: ${tokens.fontSize2};
    line-height: 20px;
    font-weight: 400;
  }
  p:last-child {
    margin-bottom: 0;
  }
`
