import { useRef } from 'react'

import SubscriptionClient from '../../services/SubscriptionClient/SubscriptionClient'

const subscriptionClient = new SubscriptionClient()

export default function useSubscriptionClient() {
  const subscriptionClientRef = useRef(subscriptionClient)

  return subscriptionClientRef.current
}
