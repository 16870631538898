import styled from 'styled-components'
// You can use variables from veneer tokens and do something like the below
import tokens from '@veneer/tokens'

export const HeaderIcon = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`

export const HeaderContainer = styled.div`
  text-align: center;
`

export const HeaderTitle = styled.h1`
  font-size: ${tokens.fontSize6};
  line-height: ${tokens.lineHeight6};
  color: #464d50;
  font-weight: 300;
`

export const HeaderMessage = styled.div`
  line-height: ${tokens.lineHeight4};
`
export const HeaderContact = styled.span`
  line-height: ${tokens.lineHeight4};
  font-weight: bold;
`
