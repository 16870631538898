import React from 'react'
import { PaymentDetail } from '@/types/OrderList'
import Images from '@/assets/images'

const PayPal = ({ details }: { details: Partial<PaymentDetail> }) => {
  return (
    <>
      <img
        src={Images.paypalNew}
        alt={details.paymentMethod.payment_type}
        tabIndex={0}
      />
      {details.paymentMethod?.name_on_card && (
        <p className="name">{details.paymentMethod.name_on_card}</p>
      )}
      {details.paymentMethod?.email_address && (
        <p className="email">{details.paymentMethod.email_address}</p>
      )}
    </>
  )
}

export default PayPal
