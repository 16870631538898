import tokens from '@veneer/tokens'
import styled from 'styled-components'

export const StyledPaymentDetail = styled.div`
  color: ${tokens.colorGray10};
  font-size: ${tokens.fontSize2};
  line-height: ${tokens.lineHeight2};
  margin-top: ${tokens.space2};
  img {
    margin-bottom: 8px;
  }

  p {
    margin-bottom: 4px;
  }
`
