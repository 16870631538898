const forceLogin = () => {
  if (window.Portal) {
    window.Portal.auth.forceLogin()
  } else if (window.Shell) {
    window.Shell.v1.authProvider.forceLogin()
  }
}

export const AuthProvider = {
  forceLogin: forceLogin
}

export default forceLogin
