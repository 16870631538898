import {
  OrderHistoryAccordionCollapsed,
  OrderHistoryAccordionExpanded,
  publishEvent,
  ShowOrderDetailsButtonClicked
} from '@/utils/analytics'
import Accordion from '@veneer/core/dist/scripts/accordion'
import React from 'react'
import { AccordionWrapper } from './styles'

function OrderAccordion({ header, body, ...extraProps }): JSX.Element {
  const { expanded, id, orderId } = extraProps
  const [data, setData] = React.useState([
    {
      expanded: expanded ?? false,
      header,
      content: body,
      disabled: body ? false : true
    }
  ])

  const handleExpand = (_, index) => {
    if (index === undefined && typeof index === 'undefined') index = 0

    setData((state) => {
      const updatedData = [...state]
      updatedData[index].expanded = true
      return updatedData
    })

    if (orderId) {
      publishEvent(ShowOrderDetailsButtonClicked, { orderNumber: orderId })
      publishEvent(OrderHistoryAccordionExpanded, { orderNumber: orderId })
    }
  }

  const handleCollapse = (_, index) => {
    if (index === undefined && typeof index === 'undefined') index = 0
    else {
      setData((state) => {
        const updatedData = [...state]
        updatedData[index].expanded = false
        return updatedData
      })
    }
    publishEvent(OrderHistoryAccordionCollapsed, { orderNumber: orderId })
  }

  return (
    <AccordionWrapper>
      <Accordion
        appearance="dropShadow"
        gutter={12}
        items={data}
        onCollapse={handleCollapse}
        onExpand={handleExpand}
        id={id}
        data-testid={id}
      />
    </AccordionWrapper>
  )
}

export default OrderAccordion
