import { DataValveCDMEvent, SimpleUiEvent, WebAction } from '../types/analytics'

const activity = 'AcctMgmt-v01'
const screenPath = '/OrderManagementReact/'
const screenName = 'Orders'
const eventDetailVersion = '1.3.0'
const controlDetail = 'HpOneWelcomeKit' //TODO: Magenta currently doesn't provide bundle name, we are hardcoding control detail until we get bundle name.

export const publishEvent = (event: Partial<SimpleUiEvent>, items = null) => {
  const publishCdmEvents = window?.Shell?.v1?.analytics?.publishCdmEvents
  const eventDetailType =
    'com.hp.cdm.domain.telemetry.type.eventDetail.category.simpleUi.version.1'
  const eventCategory = 'simpleUi'
  const version = '1.4.0'

  if (!publishCdmEvents) {
    console.error('window.Shell.v1.analytics.publishCdmEvents is not defined')
    return
  }

  if (items) {
    event.actionAuxParams = buildAnalyticsQueryString(items)
  }

  const eventToLog: DataValveCDMEvent = {
    dateTime: new Date().toISOString(),
    eventDetailType,
    eventCategory,
    eventDetail: event,
    version
  }
  publishCdmEvents([eventToLog])
}

export const buildAnalyticsQueryString = (items) => {
  return decodeURIComponent(new URLSearchParams(items).toString())
}

// Analytics Events
export const OrderHistoryScreenDisplayed: Partial<SimpleUiEvent> = {
  activity,
  action: WebAction.screenDisplayed,
  screenPath,
  screenName,
  version: eventDetailVersion
}

export const OrderHistoryModuleDisplayed: Partial<SimpleUiEvent> = {
  activity,
  action: WebAction.moduleDisplayed,
  screenPath,
  screenName,
  controlName: 'Order',
  controlDetail,
  version: eventDetailVersion
}

export const OrderHistoryAccordionExpanded: Partial<SimpleUiEvent> = {
  activity,
  action: WebAction.controlAccordionExpanded,
  screenPath,
  screenName,
  controlName: 'Order',
  controlDetail,
  version: eventDetailVersion
}

export const OrderHistoryAccordionCollapsed: Partial<SimpleUiEvent> = {
  activity,
  action: WebAction.controlAccordionCollapsed,
  screenPath,
  screenName,
  controlName: 'Order',
  controlDetail,
  version: eventDetailVersion
}

export const ShowOrderDetailsButtonClicked: Partial<SimpleUiEvent> = {
  activity,
  action: WebAction.controlButtonClicked,
  screenPath,
  screenName,
  controlName: 'OrderNumberDetails',
  version: eventDetailVersion
}

export const TrackOrderButtonClicked: Partial<SimpleUiEvent> = {
  activity,
  action: WebAction.controlHyperLinkClicked,
  screenPath,
  screenName,
  controlName: 'TrackOrder',
  controlDetail,
  version: eventDetailVersion
}

export const ViewStatementLinkClicked: Partial<SimpleUiEvent> = {
  activity,
  action: WebAction.controlHyperLinkClicked,
  screenPath,
  screenName,
  controlName: 'ViewStatement',
  controlDetail,
  version: eventDetailVersion
}

export const SeeAllHistoryOrdersSelected: Partial<SimpleUiEvent> = {
  activity,
  action: WebAction.controlButtonClicked,
  screenPath,
  screenName,
  controlName: 'SeeAllHistoryOrders',
  controlDetail,
  version: eventDetailVersion
}

export const ContactSupportLinkClicked: Partial<SimpleUiEvent> = {
  activity,
  action: WebAction.controlHyperLinkClicked,
  screenPath,
  screenName,
  controlName: 'Support',
  controlDetail,
  version: eventDetailVersion
}
