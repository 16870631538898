import React from 'react'
import Card from '../Card'
import { CardContent } from './styles'
import Header from './Header'
import StateType from '../../types/StateType'

function InformationCard(props) {
  const message = props.message
  const messageType: StateType = props.messageType || StateType.ERROR

  return (
    <Card>
      <CardContent data-testid="MsgBoundaryTest">
        <Header stateType={messageType} message={message} />
      </CardContent>
    </Card>
  )
}
export default InformationCard
