import React from 'react'
import { PaymentDetail } from '@/types/OrderList'
import Images from '@/assets/images'
import useGetText from '@/hooks/useGetText'

const CreditCard = ({ details }: { details: Partial<PaymentDetail> }) => {
  const getText = useGetText('application.orderList')
  const paymentImagesMap = {
    visa: Images.visaNew,
    mastercard: Images.mastercardNew,
    discover: Images.discoverNew,
    american_express: Images.americanexpressNew,
    placeholder: Images.generic
  }
  return (
    <>
      <img
        src={paymentImagesMap[details.paymentMethod.card_type]}
        alt={details.paymentMethod.card_type}
        tabIndex={0}
      />
      <p className="name">{details.paymentMethod.name_on_card}</p>
      <p className="number">{`XXXX-XXXX-XXXX-${details.paymentMethod.last_four}`}</p>
      <p className="expiry">
        {`${getText('expires')} ${details.paymentMethod.expiration_date}`}
      </p>
    </>
  )
}

export default CreditCard
