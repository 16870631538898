import tokens from '@veneer/tokens'
import styled from 'styled-components'

export const StyledContainer = styled.div`
  font-family: ${tokens.fontFamilyRegular};

  .vn-animated-height__wrapper-inner > div {
    margin: 0;
    padding: 16px;
  }
`
export const StyledErrorMessage = styled.div`
  color: ${tokens.colorGray10};
  font-size: ${tokens.fontSize2};
  line-height: ${tokens.lineHeight3};

  > div {
    margin-top: 10px;
  }
`

export const SubHeading = styled.div`
  color: ${tokens.colorGray10};
  letter-spacing: 0;
  font-size: ${tokens.fontSize3};
  font-weight: 400;
  line-height: ${tokens.lineHeight3};
  margin-bottom: 40px;
  > span > a {
    color: #0278ab;
    line-height: ${tokens.lineHeight4};
    &:hover {
      text-decoration: none;
    }
    &:focus,
    &:active {
      border: 1px solid ${tokens.colorHpBlue6};
    }
  }
`
