import { useEffect, useState } from 'react'
import { AssetsProvider } from '@jarvis/web-assets-provider'
import AssetsProviderFactory from '../../assets/AssetsProviderFactory'
import getLocation from '../../helpers/getLocation'
import useAppActions from '../../hooks/useAppActions'

const useAssetsProvider = () => {
  const [assetsProvider, setStateAssetsProvider] =
    useState<AssetsProvider | null>()
  const { setAssetsProvider } = useAppActions()

  useEffect(() => {
    const { language, country } = getLocation()
    if (language && country) {
      const assetsProviderFactory = AssetsProviderFactory.create(
        language,
        country
      )
      setStateAssetsProvider(() => assetsProviderFactory)
      setAssetsProvider(assetsProviderFactory)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  return assetsProvider
}

export default useAssetsProvider
