import tokens from '@veneer/tokens'
import styled from 'styled-components'
import { smartQueries } from '../../../utils/device/device'

export const StyledContainer = styled.div`
  font-family: ${tokens.fontFamilyRegular};
  margin-bottom: 30px;

  .vn-animated-height__wrapper-inner > div {
    margin: 0;
    padding: 0 !important;
    border-color: ${tokens.colorGray3};
  }

  #orderAccordion > div {
    box-shadow: none;
  }

  [aria-expanded] {
    align-items: flex-start;

    svg {
      margin-top: ${tokens.space3};
      margin-right: ${tokens.space6};
      fill: ${tokens.colorHpBlue6};
    }
    .order-total {
      position: absolute;
      right: ${tokens.space4};
      top: 61px;
      text-align: right;
      font-size: ${tokens.fontSize1};
      color: ${tokens.colorGray7};
      letter-spacing: 0.5px;

      ${smartQueries.mobileXS(`
        top: 112px;
      `)}

      .total {
        color: ${tokens.colorGray7};
        font-size: ${tokens.fontSize5};
        line-height: ${tokens.lineHeight5};
        display: block;
        margin-bottom: -4px;
      }

      .total + span {
        line-height: ${tokens.lineHeight2};
        letter-spacing: 0;
      }
    }
  }
`

export const StyledPaymentDeliveryDetails = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding-left: ${tokens.space3};
  margin: 4px 0 12px;
  border-top: solid 1px ${tokens.colorGray3};
`

export const StyledOrderDetailContainer = styled.div`
  font-family: ${tokens.fontFamilyRegular};

  .heading-2 {
    font-family: ${tokens.fontFamilyRegular};
    color: ${tokens.colorGray10};
    font-size: ${tokens.fontSize4};
    line-height: ${tokens.lineHeight4};
    font-weight: 400;
    margin-top: 16px;
    margin-bottom: 16px;
  }
`
