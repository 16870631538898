import Images from '@/assets/images'
import { DeliveryDetail } from '@/types/OrderList'
import React from 'react'
import { StyledDeliveryDetails } from './styles'

export default function DeliveryDetails(props: {
  detail: Partial<DeliveryDetail>
}) {
  return (
    <StyledDeliveryDetails data-testid="delivery-details">
      <img src={Images.fedex} alt="Fedex" tabIndex={0} />
      <p className="name">{props.detail.name}</p>
      <p className="addressLine1">{props.detail.addressLine1}</p>
      <p className="addressLine2">{props.detail.addressLine2}</p>
    </StyledDeliveryDetails>
  )
}
