import React, { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'

import { Order } from 'src/types/OrderList'
import useSubscriptionClient from '../../../hooks/useSubscriptionClient'
import useGetText from '../../../hooks/useGetText'
import OrderItem from '../Order'
import { StyledContainer, SubHeading } from './styles'
import NoOrders from '@/components/OrderHistory/NoOrders'
import ErrorCard from '@/components/ErrorCard/ErrorCard'

export default function OrderContainer() {
  const subscriptionClient = useSubscriptionClient()
  const getText = useGetText('application.orderList.noOrders')
  const [orderHistory, setOrderHistory] = useState<Order[]>()
  const [showError, setShowError] = useState<boolean>()
  const [showLoading, setShowLoading] = useState<boolean>(true)
  const [showNoOrders, setShowNoOrders] = useState<boolean>(false)

  useEffect(() => {
    subscriptionClient
      .getOrders()
      .then((orders) => {
        if (!orders) setShowNoOrders(true)
        setOrderHistory(orders)
        setShowError(false)
        setShowLoading(false)
      })
      .catch((e) => {
        // user wit hno orders will return
        // 404, hence showing empty state
        if (e.response && e.response.status === 404) {
          setShowNoOrders(true)
          setShowError(false)
          setShowLoading(false)
          return
        }
        setShowNoOrders(false)
        setShowError(true)
        setShowLoading(false)
      })
  }, [subscriptionClient])

  if (showError) return <ErrorCard getText={getText}></ErrorCard>

  if (showLoading) return <Skeleton count={3} />

  if (orderHistory?.length === 0 || showNoOrders) return <NoOrders />
  return (
    <StyledContainer>
      {!showNoOrders && (
        <SubHeading>
          <span>
            {getText('message2')}
            <a href="https://www.hp.com/shop" target="_blank" rel="noreferrer">
              {getText('hpStore')}
            </a>{' '}
            {getText('message3')}
          </span>
        </SubHeading>
      )}
      {orderHistory?.map((order, index) => {
        return <OrderItem order={order} key={index} />
      })}
    </StyledContainer>
  )
}
