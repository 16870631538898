import React from 'react'
import useGetText from '@/hooks/useGetText'
import { StyledOrderDetail } from './styles'
import { Order } from '@/types/OrderList'
import { IconChevronRight } from '@veneer/core/dist/scripts/icons'
import {
  ViewStatementLinkClicked,
  publishEvent,
  ContactSupportLinkClicked
} from '@/utils/analytics'
import { SimpleUiEvent } from '@/types/analytics'

export default function Support(props: { order: Order }) {
  const getText = useGetText('application.orderList.orderDetail')

  const onLinkClick = (analyticEvent: Partial<SimpleUiEvent>) =>
    publishEvent(analyticEvent, { orderNumber: props.order.orderId })

  return (
    <StyledOrderDetail>
      <div className="statement-support">
        <a
          href="statements"
          data-testid="statementLink"
          onClick={() => onLinkClick(ViewStatementLinkClicked)}
          tabIndex={0}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              event.currentTarget.click()
            }
          }}
          id="statement-link"
        >
          {getText('statementLink')}
          <IconChevronRight color="colorHpBlue6" size={20} />
        </a>
        <a
          href="https://support.hp.com/contact/all-in-plan-serial-number"
          data-testid="contactUsLink"
          target="_blank"
          onClick={() => onLinkClick(ContactSupportLinkClicked)}
          rel="noreferrer"
          tabIndex={0}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              event.currentTarget.click()
            }
          }}
          id="contact-us-link"
        >
          {getText('contactSupport')}
          <IconChevronRight color="colorHpBlue6" size={20} />
        </a>
      </div>
    </StyledOrderDetail>
  )
}
