import InformationCard from '@/components/InformationCard'
import { OrderHelper } from '@/helpers/transformOrderResponseHelper'
import useGetCustomerOrderById from '@/hooks/useGetCustomerOrderById'
import useGetText from '@/hooks/useGetText'
import { Order } from '@/types/OrderList'
import { OrderHistoryModuleDisplayed, publishEvent } from '@/utils/analytics'
import React, { useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import OrderAccordion from '../OrderAccordion'
import { HeaderChevronSection } from '../OrderAccordion/styles'
import { StyledErrorMessage } from '../OrderContainer/styles'
import DeliveryDetails from './DeliveryDetails'
import OrderHeader from './OrderHeader'
import Products from './Product'
import {
  StyledContainer,
  StyledOrderDetailContainer,
  StyledPaymentDeliveryDetails
} from './styles'
import Support from './Support'
import { usePaymentMethod } from '@/hooks/usePaymentMethod/usePaymentMethod'
import PaymentMethodInfo from './PaymentMethodInfo'

export default function OrderItem({ order }: { order: Order }) {
  const getText = useGetText('application.orderList')

  const { loading, error, data } = useGetCustomerOrderById(order.orderId)

  const commerceSubscriptionId =
    order.pcs[0]?.commerceSubscriptionId ||
    order.printers[0]?.commerceSubscriptionId

  const {
    data: paymentInfo,
    isLoading,
    error: paymentInfoError
  } = usePaymentMethod(commerceSubscriptionId)

  const orderBody = (
    <StyledOrderDetailContainer data-orderid={order.orderId}>
      <Products order={order} />

      <StyledPaymentDeliveryDetails>
        {order.paymentDetail && order.deliveryDetail && (
          <h2 className="heading-2">{getText('paymentAndDelivery.heading')}</h2>
        )}
        {order.paymentDetail && (
          <PaymentMethodInfo details={order.paymentDetail} />
        )}
        {order.deliveryDetail && (
          <DeliveryDetails detail={order.deliveryDetail}></DeliveryDetails>
        )}
      </StyledPaymentDeliveryDetails>

      <Support order={order} />
    </StyledOrderDetailContainer>
  )

  useEffect(() => {
    publishEvent(OrderHistoryModuleDisplayed, { orderNumber: order.orderId })
  }, [])

  if (error || paymentInfoError)
    return (
      <StyledErrorMessage data-testid="error-occured">
        <InformationCard message="apiFailed" />
      </StyledErrorMessage>
    )

  if ((loading || isLoading) && !error)
    return (
      <div data-testid="loading">
        <Skeleton count={3} />
      </div>
    )

  // adding missing information in order object once data is available
  data && OrderHelper.updateOrderObjCustomerData(data, order)

  paymentInfo?.payment_method_details &&
    OrderHelper.updatePaymentDetails(paymentInfo.payment_method_details, order)

  return (
    <StyledContainer data-testid="order-item">
      {data && order && (
        <OrderAccordion
          id="orderAccordion"
          orderId={order.orderId}
          expanded={false}
          header={{
            centralArea: <OrderHeader order={order} />,
            endArea: <HeaderChevronSection />,

            'aria-label': 'order'
          }}
          body={!isLoading && orderBody}
        />
      )}
    </StyledContainer>
  )
}
