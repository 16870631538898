const stackNumber = {
  0: 'dev',
  1: 'pie',
  2: 'stage',
  3: 'prod'
}

const stackMap = (stack: number): string => {
  return stackNumber[stack]
}

export const getStack = (stack: number | string): string => {
  return typeof stack === 'number' ? stackMap(stack) : stack
}

export const getStackNumber = (value: string) => {
  return Object.keys(stackNumber).find((key) => stackNumber[key] === value)
}
