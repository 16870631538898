import React, { useEffect } from 'react'
import {
  publishEvent,
  OrderHistoryScreenDisplayed
} from '../../utils/analytics'
import useGetText from '../../hooks/useGetText'
import OrderContainer from './OrderContainer'
import { Container, Heading } from './styles'
import LaunchDarkly from '../LaunchDarkly/LaunchDarkly'

export default function OrdersList() {
  const getText = useGetText('application.orderList')

  useEffect(() => {
    publishEvent(OrderHistoryScreenDisplayed)
  }, [])

  return (
    <LaunchDarkly>
      <Container>
        <Heading>{getText('heading')}</Heading>
        <OrderContainer />
      </Container>
    </LaunchDarkly>
  )
}
