import {
  GetCustomerOrderByIdDocument,
  GetCustomerOrderByIdQuery,
  GetCustomerOrderByIdQueryVariables
} from '@/__generated__/graphql'
import { QueryResult, useQuery } from '@apollo/client'

function useGetCustomerOrderById(
  orderId: string
): QueryResult<GetCustomerOrderByIdQuery, GetCustomerOrderByIdQueryVariables> {
  return useQuery(GetCustomerOrderByIdDocument, {
    variables: {
      orderId: orderId
    }
  })
}

export default useGetCustomerOrderById
