import forceLogin from '@/helpers/forceLogin'
import {
  getLoginAttempts,
  saveLoginAttempts
} from '../services/SessionHelperService/sessionHelperService'

export function forceLoginHandling() {
  const attempsInfo = getLoginAttempts()
  if (!attempsInfo) {
    saveLoginAttempts(1)
    forceLogin()
    return
  }
  const timeNow = new Date()
  const lastAttempt = new Date(attempsInfo.lastAttempt)

  // time difference of two timestamps
  const elapseTimeInMin = Math.ceil(
    (timeNow.getTime() - lastAttempt.getTime()) / 1000 / 60
  )

  if (attempsInfo.attempts > 2 && elapseTimeInMin < 14) {
    saveLoginAttempts(0)
    return
  }

  if (elapseTimeInMin > 15) {
    attempsInfo.attempts = 1
  } else {
    attempsInfo.attempts++
  }
  saveLoginAttempts(attempsInfo.attempts)
  forceLogin()
}
