import setAssetsProvider from './setAssetsProvider'
import setIsFetching from './setIsFetching'
import setErrorFetch from './setErrorFetch'
import setSucessFetch from './setSuccessFetch'

export { default as ActionsType } from './actionsType'

export type Action =
  | ReturnType<typeof setAssetsProvider>
  | ReturnType<typeof setIsFetching>
  | ReturnType<typeof setErrorFetch>
  | ReturnType<typeof setSucessFetch>

export default {
  setAssetsProvider,
  setIsFetching,
  setErrorFetch,
  setSucessFetch
}
