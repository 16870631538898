import React, { useEffect, useState } from 'react'
import { getStack } from '@/helpers/getStack'
import setEnvironmentVariables from '@/helpers/setEnvironmentVariables'
import OrderManagement from '../OrderManagement'

function RoutesProvider(props) {
  const { stack } = props
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setEnvironmentVariables(getStack(stack))
    setIsLoading(false)
  }, [stack])
  if (isLoading) return null

  return <OrderManagement {...props} />
}

export default RoutesProvider
