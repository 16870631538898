import styled from 'styled-components'
import { tokens } from '@veneer/tokens'

const { fontSize6, fontSize3, lineHeight3, space2, space5 } = tokens

export const Container = styled.div`
  display: flex;
  padding: 0px 40px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  margin-top: 40px;
  font-family: ${({ theme }) => theme.fontTextRegular};

  .title {
    font-size: ${fontSize6};
    line-height: 32px;
  }
  .subtitle {
    font-size: ${fontSize3};
    line-height: ${lineHeight3};
    margin-top: ${space2};
  }
  .reload-button {
    margin-top: ${space5};
  }
`
