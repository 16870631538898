import { OrderHelper } from '@/helpers/transformOrderResponseHelper'
import useGetProductBySKU from '@/hooks/useGetProductBySKU'
import useGetText from '@/hooks/useGetText'
import useSubscriptionClient from '@/hooks/useSubscriptionClient'
import {
  Device,
  Fulfillment,
  FulfillmentStatus,
  Order
} from '@/types/OrderList'
import React, { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import Item from './Item'
import { StyledProduct } from './styles'

export default function Product(props: { order: Order }) {
  const errorText = useGetText('application.informationMessages')
  const items = props.order.devices
  const order = props.order
  const { loading, error, data } = useGetProductBySKU(order.productSKUs)

  data && OrderHelper.updateProductData(data, order)

  const subscriptionClient = useSubscriptionClient()

  const [fulfillmentResponse, setFulfillmentResponse] =
    useState<Fulfillment[]>()

  const [showFulfillmentLoading, setShowFulfillmentLoading] =
    useState<boolean>(true)

  const [showFulfillmentError, setFulfillmentShowError] =
    useState<boolean>(false)

  useEffect(() => {
    subscriptionClient
      .getFulfillment()
      .then((response) => {
        setFulfillmentResponse(response)
        setShowFulfillmentLoading(false)
        setFulfillmentShowError(false)
      })
      .catch((err) => {
        setFulfillmentShowError(true)
        setShowFulfillmentLoading(false)
      })
  }, [])

  const fulfillmentObj: FulfillmentStatus = {
    loading: showFulfillmentLoading,
    fulFillments: fulfillmentResponse,
    error: showFulfillmentError
  }

  if (loading && !error)
    return (
      <div data-testid="loading">
        <Skeleton count={3} />
      </div>
    )

  if (error)
    return (
      <div data-testid="error">
        {errorText('generic.title')}
        <br />
        {errorText('generic.message')}
      </div>
    )

  const showItem = (orderItem: Device): boolean => {
    const fulfillmentOrder = OrderHelper.getFulfillmentObjById(
      fulfillmentObj.fulFillments,
      orderItem.fulfillmentId
    )
    return Boolean(fulfillmentOrder?.orderItems[orderItem.sku])
  }

  return (
    <>
      {items
        .filter((orderItem) => showItem(orderItem))
        .map((orderItem, index) => (
          <StyledProduct
            addDivider={index > 0}
            key={index}
            data-testid={`product-item-${index}`}
          >
            <Item
              item={orderItem}
              fulfillmentObj={fulfillmentObj}
              productInfo={data}
            />
          </StyledProduct>
        ))}
    </>
  )
}
