import React from 'react'
import useGetText from '@/hooks/useGetText'
import { StyledNoOrders } from './styles'

export default function NoOrders() {
  const getText = useGetText('application.orderList.noOrders')
  return (
    <StyledNoOrders>
      <p>{getText('message1')}</p>
      <div className="enroll-hp-one">{getText('message4')}</div>
    </StyledNoOrders>
  )
}
