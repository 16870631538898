import tokens from '@veneer/tokens'
import styled from 'styled-components'

const { borderRadius4 } = tokens

export const HeaderChevronSection = styled.span`
  font-family: ${tokens.fontFamilyRegular};
  letter-spacing: 0.04em;
  display: flex;
  color: ${tokens.colorHpBlue6};
  font-size: ${tokens.fontSize1};
  line-height: ${tokens.lineHeight2};
  > svg {
    color: ${tokens.colorHpBlue6};
  }
`

export const AccordionWrapper = styled.div`
  > #orderAccordion {
    > div {
      padding: ${tokens.space2} 0 0 0;
      &:has(div[aria-expanded='false']) {
        border-radius: ${borderRadius4} ${borderRadius4} 0 0;
      }
      > div {
        margin: 0;
        padding: 0;
      }
    }
  }
`
