import tokens from '@veneer/tokens'
import styled from 'styled-components'
import { smartQueries } from '../../../../utils/device/device'

type StyledProductItemProps = {
  addDivider: boolean
}

export const StyledProduct = styled.div<StyledProductItemProps>`
  border-top: ${({ addDivider }) =>
    addDivider ? `1px solid  ${tokens.colorGray3}` : ''};
  padding: ${tokens.space5} ${tokens.space5} ${tokens.space4} ${tokens.space3};
  ${smartQueries.mobileXS(`
    padding: 20px 16px 16px;
  `)}

  &:last-child {
    margin-bottom: 0;
  }

  .stepper-container {
    max-width: 350px;

    #fulfillmentStepper {
      ${smartQueries.tabletAndLower(`
        padding-left: 0 !important;
      `)}
    }

    ${smartQueries.tabletAndLower(`
      max-width: auto;
    `)}

    .order-status {
      margin-bottom: ${tokens.space4};
    }
  }

  #fulfillmentStepper > div:nth-child(2n) {
    min-width: 20px;
  }
`
