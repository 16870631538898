import alipay from './alipay.svg'
import amex from './amex.svg'
import americanexpressNew from './americanexpressNew.svg'
import billing from './billing.svg'
import deliveryOptions from './delivery-options.svg'
import diners from './diners.svg'
import discover from './discover.svg'
import discoverNew from './discoverNew.svg'
import elo from './elo.svg'
import fedex from './fedex.svg'
import generic from './generic.svg'
import hiper from './hiper.svg'
import hipercard from './hipercard.svg'
import hpOne from './hpone.svg'
import hpAllinPLan from './hpAllinPlan.svg'
import iconMasterCard from './master_card.svg'
import iconShippingBillingSignIn from './shipping-and-billing-sign-in-icon.svg'
import jcb from './jcb.svg'
import maestro from './maestro.svg'
import mastercard from './mastercard.svg'
import mastercardNew from './mastercardNew.svg'
import mir from './mir.svg'
import orderSummary from './order-summary.svg'
import paypal from './paypal.svg'
import paypalNew from './paypalNew.svg'
import shipping from './shipping.svg'
import unionpay from './unionpay.svg'
import visa from './visa.svg'
import visaNew from './visaNew.svg'
import printerPlaceholder from './printer-placeholder.svg'

const Images = {
  alipay,
  amex,
  americanexpressNew,
  billing,
  deliveryOptions,
  diners,
  discover,
  discoverNew,
  elo,
  fedex,
  generic,
  hiper,
  hipercard,
  hpOne,
  hpAllinPLan,
  iconShippingBillingSignIn,
  iconMasterCard,
  jcb,
  maestro,
  mastercard,
  mastercardNew,
  mir,
  orderSummary,
  paypal,
  paypalNew,
  shipping,
  unionpay,
  visa,
  visaNew,
  printerPlaceholder
}

export default Images
