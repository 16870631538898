import React from 'react'
import RoutesProvider from '../src/pages/RoutesProvider'
import { ShellProps } from '../src/types/shell'
import { MfePropsType } from '../src/types/mfeProps'
import projectNames from '../src/configs/projectNames'

declare global {
  interface Window {
    Shell: ShellProps
  }
}

/**
 * @function Root Main function
 * @param props
 * @returns
 */
export default function Root({ ...props }: MfePropsType) {
  // You can find the "stack" information by props

  return (
    <section
      className={`${projectNames.namespace}`}
      id={projectNames.packageJsonName}
    >
      <RoutesProvider {...props} />
    </section>
  )
}
