import { QueryResult, useQuery } from '@apollo/client'
import {
  GetProductsBySkuDocument,
  GetProductsBySkuQuery,
  GetProductsBySkuQueryVariables,
  InputMaybe
} from '../../__generated__/graphql'

function useGetProductBySKU(
  products: InputMaybe<string[]>
): QueryResult<GetProductsBySkuQuery, GetProductsBySkuQueryVariables> {
  return useQuery(GetProductsBySkuDocument, {
    variables: {
      products
    }
  })
}

export default useGetProductBySKU
