import { useEffect, useState } from 'react'
import useSubscriptionClient from '../useSubscriptionClient'
import sendOpenTelemetryEvent from '@/helpers/sendOpenTelemetryEvent'

export const usePaymentMethod = (subId?: string) => {
  const client = useSubscriptionClient()

  const [pmRef, setPmRef] = useState()
  const [pmRefIsLoading, setPmRefIsLoading] = useState(false)
  const [pmRefError, setPmRefError] = useState(false)

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)
  const [info, setInfo] = useState(undefined)

  useEffect(() => {
    const getCommerceGInfo = async () => {
      setPmRefIsLoading(true)
      setIsLoading(true)
      try {
        const { pmReference } = await client.getCommerceGeneralInfo(subId)
        setPmRef(pmReference)
      } catch (err) {
        sendOpenTelemetryEvent(
          `Error while fetching data from commerce api : ` + err?.message
        )
        setPmRefError(true)
      }
      setPmRefIsLoading(false)
    }

    if (subId) {
      getCommerceGInfo()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subId])

  useEffect(() => {
    const getPaymentMethodsInfo = async () => {
      try {
        const data = await client.getPaymentMethods(pmRef)
        setInfo(data)
      } catch (err) {
        sendOpenTelemetryEvent(
          `Error while fetching data from payment-methods api: ` + err?.message
        )
        setError(true)
      }
      setIsLoading(false)
    }

    if (pmRef && !pmRefIsLoading && !pmRefError) {
      getPaymentMethodsInfo()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pmRef, pmRefIsLoading, pmRefError])

  return {
    data: info,
    error,
    isLoading
  }
}
