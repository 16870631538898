import { curry } from 'ramda'
import AssetsProviderFactory from '../assets/AssetsProviderFactory'
import { ActionsType, Action } from '../actions'
import AppState from '../types/AppState'
import assignState from '../helpers/assignState'
import FetchDataState from '../types/FetchDataState'

const getInitialState = (): AppState => ({
  settings: {
    assetsProvider: AssetsProviderFactory.create(
      process.env.REACT_APP_DEFAULT_LANGUAGE,
      process.env.REACT_APP_DEFAULT_COUNTRY
    )
  },
  layoutInfo: {
    isFetching: false
  },
  orderInfo: {
    isFetching: false
  }
})

const factorySuccessFetch = <T>(data: T): FetchDataState<T, undefined> => ({
  isFetching: false,
  data
})

const factorErrorFetch = <T>(error: T): FetchDataState<undefined, T> => ({
  isFetching: false,
  error
})

const orderManagementReducer = (state: AppState, action: Action): AppState => {
  const assignPopulated = curry(assignState)(state)

  switch (action.type) {
    case ActionsType.SET_ASSETS_PROVIDER:
      return assignPopulated('settings.assetsProvider', action.data, false)

    case ActionsType.SET_IS_FETCHING: {
      const { key, value } = action.data
      return assignPopulated(`${key}.isFetching`, value, false)
    }

    case ActionsType.SET_SUCCESS_FETCH: {
      const { key, data } = action.data
      return assignPopulated(key, factorySuccessFetch(data), false)
    }

    case ActionsType.SET_ERROR_FETCH: {
      const { key, error } = action.data
      return assignPopulated(key, factorErrorFetch(error), false)
    }
    default:
      return state
  }
}

export const initialState = getInitialState()

export default orderManagementReducer
