import tokens from '@veneer/tokens'
import styled from 'styled-components'
import { smartContainer } from '../../utils/device/device'

export const CardContent = styled.div`
  width: 90%;
  margin: auto;
  padding-bottom: 30px;
  font-family: ${tokens.fontFamilyRegular};
  ${smartContainer}
`
