import ActionsType from './actionsType'
import InfoKeys from '../types/InfoKeys'

type ReturnSetIsFetching = {
  type: ActionsType.SET_IS_FETCHING
  data: {
    key: InfoKeys
    value: boolean
  }
}

export default function setIsFetching(
  key: InfoKeys,
  value: boolean
): ReturnSetIsFetching {
  return {
    type: ActionsType.SET_IS_FETCHING,
    data: {
      key,
      value
    }
  }
}
