import React from 'react'
import { StyledPaymentDetail } from './styles'
import CreditCard from './CreditCard/CreditCard'
import PayPal from './PayPal/PayPal'
import { PaymentDetail } from '@/types/OrderList'
import Images from '@/assets/images'

export default function PaymentMethodInfo({
  details
}: {
  details: Partial<PaymentDetail>
}) {
  const paymentMethodTypeMap = {
    credit_card: <CreditCard details={details} />,
    paypal: <PayPal details={details} />
  }
  return (
    <StyledPaymentDetail data-testid="payment-details">
      {details.paymentMethod?.payment_type ? (
        paymentMethodTypeMap[details.paymentMethod.payment_type]
      ) : (
        <img src={Images.placeholder} alt="placeholder" />
      )}
    </StyledPaymentDetail>
  )
}
