import React from 'react'
import tokens from '@veneer/tokens'
import IconCheckCircle from '@veneer/core/dist/scripts/icons/icon_check_circle'
import IconWarning from '@veneer/core/dist/scripts/icons/icon_warning'
import useGetText from '../../../hooks/useGetText'
import StateType from '../../../types/StateType'
import {
  HeaderContainer,
  HeaderIcon,
  HeaderTitle,
  HeaderMessage,
  HeaderContact
} from './styles'

export default function Header(props) {
  const getText = useGetText('application.informationMessages')
  const msg = props.message
  const contact = props.contact

  const msgObj = {
    icon:
      props.stateType == StateType.SUCCESSFUL ? (
        <IconCheckCircle
          size={52}
          style={{ fill: tokens.colorGreen6, marginTop: '30px' }}
        />
      ) : (
        <IconWarning size={52} style={{ fill: tokens.colorOrange7 }} />
      ),
    title: getText(msg + '.title'),
    message: getText(msg + '.message'),
    contact: contact ? getText(msg + '.contact') : null
  }

  return (
    <HeaderContainer>
      <HeaderIcon> {msgObj.icon} </HeaderIcon>
      <HeaderTitle>{msgObj.title}</HeaderTitle>
      <HeaderMessage>
        {msgObj.message}
        {msgObj.contact && (
          <HeaderContact>{` ${msgObj.contact}`}</HeaderContact>
        )}
      </HeaderMessage>
    </HeaderContainer>
  )
}
