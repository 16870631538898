import tokens from '@veneer/tokens'
import styled from 'styled-components'
import { smartQueries } from '../../../../../utils/device/device'

export const StyledErrorMessage = styled.div`
  color: ${tokens.colorGray6};
  font-size: 10px;
  line-height: ${tokens.lineHeight3};
  display: flex;
  align-items: center;

  > div {
    margin-top: 10px;
  }

  svg {
    margin-right: 5px;
  }
`

export const StyledItem = styled.div`
  .fulfillment-status {
    display: flex;
    justify-content: space-between;

    ${smartQueries.mobileXS(`
      flex-wrap: wrap;
      flex-direction: column;

      .stepper-container {
        margin-top: 10px;
        margin-bottom: 5px;
        margin-left: auto;
      }
    `)}
  }

  .product-info {
    align-items: center;
    display: flex;
    line-height: ${tokens.lineHeight2};
    margin-top: 2px;
  }

  .product-image {
    margin-right: ${tokens.space4};
    width: 80px;

    img {
      max-width: 100%;
    }
  }

  .product-desc {
    color: ${tokens.colorGray10};
    font-family: ${tokens.fontTextRegular};

    > strong {
      letter-spacing: 0.02em;
      display: block;
      font-weight: 400;
      font-size: ${tokens.fontSize3};
      line-height: ${tokens.lineHeight3};
    }

    > span {
      > strong {
        font-family: ${tokens.fontTextRegular};
        color: ${tokens.colorGray5};
        font-weight: normal;
      }
      display: block;
      font-size: ${tokens.fontSize1};
      color: ${tokens.colorGray7};
      margin-top: ${tokens.space1};
    }
  }
  .product-name {
    font-size: ${tokens.fontSize3};
    line-height: ${tokens.lineHeight3};
  }

  .order-status {
    font-size: ${tokens.fontSize1};
    line-height: ${tokens.lineHeight2};
    letter-spacing: 0.5px;
    a {
      color: ${tokens.colorHpBlue6};
      text-decoration: none;
    }
    > span {
      > strong {
        color: ${tokens.colorRed6};
      }
      color: ${tokens.colorGray7};
      display: block;
      margin-bottom: ${tokens.space1};
      text-transform: capitalize;
    }
  }
`
