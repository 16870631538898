import tokens from '@veneer/tokens'
import { smartQueries } from '@/utils/device/device'
import styled from 'styled-components'
export const StyledNoOrders = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${tokens.fontSize3};
  line-height: ${tokens.lineHeight4};

  p {
    margin: 0;
    a {
      color: ${tokens.colorHpBlue6};
      text-decoration: none;
      line-height: ${tokens.lineHeight4};

      &:focus,
      &:active {
        border: 1px solid ${tokens.colorHpBlue6};
      }
    }
  }

  .enroll-hp-one {
    background-color: ${tokens.colorWhite};
    align-items: center;
    border-radius: 8px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    margin-top: 40px;
    min-height: 532px;
    padding: 0 10px;
    width: 100%;
    text-align: center;
    ${smartQueries.mobileXS(`
      min-height: 250px;
    `)}
  }
`
