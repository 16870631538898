import Button from '@veneer/core/dist/scripts/button'
import React from 'react'
import { Container } from './styles'

export default function ErrorCard(props) {
  const handleOnClick = () => {
    window.location.reload()
  }
  const { getText } = props

  return (
    <Container data-testid="error-message">
      <p className="title">{getText('errorScreen.title')}</p>
      <p className="subtitle">{getText('errorScreen.subtitle')}</p>
      <Button
        className="reload-button"
        appearance="secondary"
        onClick={handleOnClick}
      >
        {getText('errorScreen.action')}
      </Button>
    </Container>
  )
}
