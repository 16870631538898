import tokens from '@veneer/tokens'
import styled from 'styled-components'
import { smartQueries } from '../../utils/device/device'

export const Container = styled.div`
  font-family: ${tokens.fontFamilyRegular};
  line-height: ${tokens.lineHeight4};
  color: ${tokens.colorGray10};
  padding: 40px;
  ${smartQueries.mobileXS(`
    padding: ${tokens.space4};
  `)}
`
export const Heading = styled.h1`
  font-family: ${tokens.fontFamilyRegular};
  font-size: ${tokens.fontSize8};
  font-weight: 400;
  color: ${tokens.colorGray10};
  line-height: ${tokens.lineHeight8};
  margin-bottom: 8px;
`
