import tokens from '@veneer/tokens'
import styled from 'styled-components'

export const StyledOrderDetail = styled.div`
  border-top: solid 1px ${tokens.colorGray3};
  padding-left: ${tokens.space3};

  .statement-support {
    padding: 2px 0 8px 0;
    display: inline-block;

    a {
      align-items: center;
      color: ${tokens.colorHpBlue6};
      display: flex;
      font-size: ${tokens.fontSize2};
      text-decoration: none;
      line-height: ${tokens.lineHeight3};
      margin-bottom: 36px;
      margin-top: 18px;

      svg {
        margin-left: 8px;
      }

      &:focus {
        outline: 1px solid ${tokens.colorHpBlue6} !important;
        width: fit-content;
      }
    }

    a:last-child {
      margin-bottom: 18px;
    }
  }
`
