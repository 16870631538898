import OrdersList from '@/components/OrderHistory'
import { withAppContext } from '@/context/AppProvider'
import { getStack } from '@/helpers/getStack'
import setEnvironmentVariables from '@/helpers/setEnvironmentVariables'
import useApolloClient from '@/hooks/useApolloClient'
import useAssetsProvider from '@/hooks/useAssetsProvider'
import { ApolloProvider } from '@apollo/client'
import ThemeProvider from '@veneer/theme/dist/theme_provider'
import React from 'react'
import { Container } from './styles'

function OrderManagement(props) {
  const { stack } = props
  setEnvironmentVariables(getStack(stack))
  const client = useApolloClient()
  useAssetsProvider()
  return (
    <ApolloProvider client={client}>
      <ThemeProvider shape="sharp">
        <Container>
          <OrdersList />
        </Container>
      </ThemeProvider>
    </ApolloProvider>
  )
}

export default withAppContext(OrderManagement)
