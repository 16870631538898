import useGetText from '@/hooks/useGetText'
import {
  IconPackageDelivered,
  IconPackagePacked,
  IconPackageShipped,
  IconShoppingCartCheckmark
} from '@veneer/core/dist/scripts/icons'
import InlineNotification from '@veneer/core/dist/scripts/inline_notification'
import Stepper from '@veneer/core/dist/scripts/stepper'
import React from 'react'

import './styles.css'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const stepsTemplate: { status: string; step: React.ReactNode }[] = [
  {
    status: 'Preparing',
    step: <IconShoppingCartCheckmark />
  },
  {
    status: 'Packaging',
    step: <IconPackagePacked />
  },
  {
    status: 'Shipped',
    step: <IconPackageShipped />
  },
  {
    status: 'Delivered',
    step: <IconPackageDelivered />
  }
]

const fulfillmentStatus = [
  'new|received',
  'processing|onhold|releaseHold|pending',
  'shipped|invoiced',
  'delivered' //uses delivered flag from api instead of status
]
//TODO the following second line new fulfillmentStatus is belong to which step or errorcase?
//new, received, processing, shipped, onhold, releaseHold, cancelled, error, unknown
//invoiced, undelivered, returning, returnTimeout, returnError, returnCompleteTimeout, returnComplete, returnCancelled,
const errorCases = ['error', 'unknown', 'cancelled', null]
type VeneerStatusString = 'complete' | 'current' | 'disabled' | 'incomplete'

export default function ShipmentStatusStepper({ status, delivered = false }) {
  const errorText = useGetText('application.informationMessages')
  const errorStep = (status) => (
    <InlineNotification
      closeButton={false}
      title={errorText('fulfillmentStatusCode.error')}
      description={errorText('fulfillmentStatusCode.message')}
      hideIcon={false}
      type="warning"
    />
  )

  return errorCases.includes(status) ? (
    errorStep(status)
  ) : (
    <Stepper
      id="fulfillmentStepper"
      steps={fromStatusToSteps(delivered ? 'delivered' : status)}
    />
  )
}

export const fromStatusToSteps = (status) => {
  const current: number = fulfillmentStatus.findIndex((elm) =>
    elm.includes(status)
  )

  const indexToStatus: (arg0: number, arg1: number) => VeneerStatusString = (
    current: number,
    index: number
  ) => {
    return current === -1
      ? 'disabled'
      : current === index && current !== stepsTemplate.length - 1
      ? 'current'
      : current < index
      ? 'incomplete'
      : 'complete'
  }

  return stepsTemplate.map((elm, index) => {
    const statusString = indexToStatus(current, index)
    const step = {
      ...elm,
      status: statusString,
      tabIndex: 0
    }
    return step
  })
}

export function getStatusByFulfillmentStatus(status: string): string {
  if (!status) return ''
  const index = fulfillmentStatus.findIndex((item) =>
    item.includes(status.toLowerCase())
  )
  return stepsTemplate[index]?.status || status
}
