export enum breakpoints {
  mobileXS = 'MOBILEXS',
  mobile = 'MOBILE',
  tablet = 'TABLET',
  desktop = 'DESKTOP',
  ultraWide = 'ULTRAWIDE'
}

const deviceSize = {
  mobileXS: '250px',
  mobileXSMax: '499px',
  mobile: '500px',
  mobileMax: '640px',
  tablet: '641px',
  tabletMax: '1007px',
  desktop: '1008px',
  desktopMax: '1737px',
  ultraWide: '1738px'
}

const createMaxQuery = (querySize: string) => `(max-width: ${querySize})`
const device = {
  mobileXS: createMaxQuery(deviceSize.mobileXS),
  mobile: createMaxQuery(deviceSize.mobile),
  tablet: createMaxQuery(deviceSize.tablet),
  desktop: createMaxQuery(deviceSize.desktop),
  ultraWide: createMaxQuery(deviceSize.ultraWide)
}

const smartContainer = `
@media ${device.mobile} {
  max-width: ${deviceSize.mobile};
  width: 100%;
}
@media ${device.tablet} {
  max-width: ${deviceSize.tablet};
  width: 100%;
}
@media ${device.desktop} {
  max-width: ${deviceSize.desktop};
  width: 100%;
}
@media ${device.ultraWide} {
  max-width: ${deviceSize.ultraWide};
  width: 100%;
}
`

// media queries
const smartQueries = {
  mobileXS: (css) => `
  @media screen and (min-width: ${deviceSize.mobileXS}) and (max-width: ${deviceSize.mobileXSMax}) {
    ${css}
  }
  `,
  mobile: (css) => `
  @media screen and (min-width: ${deviceSize.mobile}) and (max-width: ${deviceSize.mobileMax}) {
    ${css}
  }
  `,
  tablet: (css) => `
  @media screen and (min-width: ${deviceSize.tablet}) and (max-width: ${deviceSize.tabletMax}) {
    ${css}
  }
  `,
  tabletAndLower: (css) => `
  @media screen and (max-width: ${deviceSize.tablet}) {
    ${css}
  }
  `,
  desktop: (css) => `
  @media screen and (min-width: ${deviceSize.desktop}) and (max-width: ${deviceSize.desktopMax}) {
    ${css}
  }
  `,
  ultraWide: (css) => `
  @media screen and (min-width: ${deviceSize.ultraWide}) {
    ${css}
  }
  `
}

// use with useDeviceWidth in component
const smartWidth = (width) => {
  let w = 0

  try {
    w = parseInt(width)
  } catch (err) {
    // eslint-disable-next-line no-empty
  }

  if (w > parseInt(deviceSize.desktopMax)) {
    // w > 1199
    return breakpoints.ultraWide
  }

  if (w > parseInt(deviceSize.tabletMax)) {
    // 1199 >= w > 991
    return breakpoints.desktop
  }

  if (w > parseInt(deviceSize.mobileMax)) {
    // 991 >= w > 576
    return breakpoints.tablet
  }

  if (w > parseInt(deviceSize.mobileXSMax)) {
    // 991 >= w > 576
    return breakpoints.mobile
  }

  if (w > parseInt(deviceSize.mobileXS)) {
    // 991 >= w > 576
    return breakpoints.mobileXS
  }

  if (w > 0) {
    // 576 >= w > 0
    return breakpoints.mobileXS
  }

  return width
}

export { deviceSize, smartContainer, smartQueries, smartWidth }

export default device
