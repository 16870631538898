import React from 'react'
import AppContext from './AppContext'
import orderManagementReducer, { initialState } from './reducer'

function AppProvider({ children }) {
  const [state, dispatch] = React.useReducer(
    orderManagementReducer,
    initialState
  )

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  )
}

export function withAppContext<Props>(
  Component: React.JSXElementConstructor<Props>
) {
  function Wrapper(props: Props) {
    return (
      <AppProvider>
        <Component {...props} />
      </AppProvider>
    )
  }

  return Wrapper
}

export default AppProvider
