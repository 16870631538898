export interface Order {
  orderPlacedAt?: string
  orderId?: string
  tenantId?: string
  orderTotal?: number
  orderCurrency?: string
  devices?: Device[]
  printers?: Partial<Printer>[]
  pcs?: Partial<PCs>[]
  productSKUs?: string[]
  paymentDetail?: Partial<PaymentDetail>
  paymentMethodDetail?: Partial<PaymentMethodDetail>
  deliveryDetail?: Partial<DeliveryDetail>
  orderDeviceTotal?: number
  orderDeviceCurrency?: string
  orderNumber?: string
}
export type Device = Partial<Printer> | Partial<PCs> | OtherProducts

export interface OtherProducts {
  entityId: string
  imageUrl: string
  name: string
  description: string
  sku: string
  parentProductSku: string
  fulfillmentId: string
  state: string
  entityType: string
  cancellationEnabled: boolean
}

export interface DeliveryDetail {
  name: string
  addressLine1: string
  addressLine2: string
}

export interface PaymentDetail {
  imageURL: string
  name: string
  paymentMethod?: Partial<PaymentMethodInfo>
}

export interface PaymentMethodInfo {
  payment_type: string
  status: string
  mid_restricted: boolean
  transaction_default: boolean
  subscription_defaul: boolean
  date_added: string
  email_address: string
  card_type: string
  name_on_card: string
  last_four: string
  expiration_date: string
}
export interface PaymentMethodDetail {
  id: string
  tenant_id: string
  billing_address_id: string
  payment_provider_id: string
  payment_method_details: Partial<PaymentMethodInfo>
}

export interface SubscriptionItem {
  deviceId?: string
  productSku?: string
  imageURL?: string
  name?: string
  description?: string
}

export interface FulfillmentResponse {
  count: number
  totalPages: number
  totalElements: number
  content: Fulfillment[]
}

export interface FulfillmentHistory {
  status: string
  eta: string
  timeStamp: string
  error: string
  trackingNumber: string
  shippingVendor: string
  trackingUrl: string
}
export interface Fulfillment {
  subscriptionId?: string
  fulfillmentOrderId?: string
  status?: string
  productType?: string
  orderType?: string
  triggerNumber?: string
  orderNumber?: string
  modelSku?: string
  shippingOption?: string
  serialNumber?: string
  eta?: string
  trackingNumber?: string
  shippingVendor?: string
  trackingUrl?: string
  timeStamp?: string
  error?: string
  history?: FulfillmentHistory[]
  parts?: Parts[]
  orderItems: { [key in string]: Parts }
}

export type Parts = {
  eta: string
  modelSku: string
  serialNumber: string
  shippingVendor: string
  status: string
  delivered: boolean
  timeStamp: string
  trackingNumber: string
}

export interface FulfillmentStatus {
  loading: boolean
  fulFillments?: Fulfillment[]
  error: boolean
}

export type OrderResponse = {
  createdTime: string
  updatedTime: string
  blId: string
  subscriptionId: string
  tenantId: string
  country: string
  state: string
  entities: Entities[]
  subscriptionTransitionState: string
  blManifestVersion: string
  id: string
}

export type Printer = {
  entityId: string
  entityType: string
  cancellationEnabled: boolean
  state: string
  name: string
  sku: string
  parentProductSku: string
  productNumber: string
  pagesPerMonth: number
  hasPrintPlan: boolean
  imageUrl: string
  fulfillmentId: string
  tenantId: string
  instantInkSKU: string
  commerceSubscriptionId?: string
}

export const cloudStorages = ['googleone', 'ms365'] as const
export type StorageType = typeof cloudStorages[number] // 'googleone' | 'ms365'

export type PCs = {
  entityId: string
  entityType: string
  cancellationEnabled: boolean
  state: string
  tenantId: string
  fulfillmentId: string
  name: string
  sku: string
  parentProductSku: string
  productNumber: string
  cloudStorageSKU: string
  cloudStorage: number
  cloudStorageUnitLabel: string
  hasCloudStorage: boolean
  storageType: StorageType
  has5G: boolean
  imageUrl: string
  commerceSubscriptionId?: string
}

export enum deviceType {
  pc = 'iot-pc',
  chromebook = 'chromebook',
  printer = 'iot-printer',
  keyboard = 'keyboard',
  mouse = 'mouse',
  pen = 'pen',
  paper = 'paper',
  software = 'software',
  carepack = 'carepack',
  printplan = 'instant-ink'
}

export enum cancelTypes {
  cancelling = 'cancelling',
  cancelledNotReceived = 'cancelled-not-received',
  cancelled = 'cancelled',
  pendingReturn = 'pending-return',
  returned = 'returned',
  inactive = 'inactive',
  deactivating = 'deactivating'
}

export enum cancelRedText {
  cancelledNotReceived = 'cancelled-not-received',
  cancelled = 'cancelled',
  pendingReturn = 'pending-return',
  returned = 'returned',
  inactive = 'inactive'
}

export type Entities = {
  entityId: string
  state: string
  userId: string
  shippingAddressId: string
  entityStartDate: string
  entityType: string
  linkedEntities: LinkedEntity[]
  commerce: Commerce
  product: Product
  source: Source
  fulfillment: IFulfillment
  entityDetails: EntityDetails
  createdAt: Date
  updatedAt: Date
}

export interface Commerce {
  type: string
  value: CommerceValue
}

export interface CommerceValue {
  subscriptionId: string
  friendlySubscriptionId: string
  purchaseResourceId: string
}

export interface Product {
  type: string
  value: ProductValue
}

export interface ProductValue {
  productSku: string
  productType: string
  parentProductSku: string
  pendingSku: string
}

export interface Source {
  type: string
  value: SourceValue
}

export interface SourceValue {
  orderId: string
  itemId: string
  parentItemId: string
}

export interface EntityDetails {
  accessoryId: string
  pendingAssociationId: string
  pendingAssociationState: string
  serialNumber: string
  uniqueDeviceId: string
  ledgerResourceId: string
}

export interface IFulfillment {
  type: string
  value: FulfillmentValue
}

export interface FulfillmentValue {
  lccSubscriptionId?: string
  fulfillmentOrderId: string
}

export interface LinkedEntity {
  type: string
  value: string
}

export interface PaymentMethodsDetails {
  payment_type: string
  status: string
  mid_restricted: boolean
  transaction_default: boolean
  subscription_default: boolean
  date_added: Date
  offer_category: string
  last_four: string
  card_type: string
  name_on_card: string
  expiration_date: string
}
export interface PaymentMethods {
  id?: string
  tenant_id?: string
  payment_provider_id?: string
  payment_method_details: Partial<PaymentMethodsDetails>
}

export interface SubscriptionResponse {
  contents: OrderResponse[]
  defaultPaginationLimit: number
  maxPaginationLimit: number
  links: Link[]
  totalCount: number
  version: string
}
export interface Link {
  rel: string
  href: string
}
