import tokens from '@veneer/tokens'
import styled from 'styled-components'

export const StyledDeliveryDetails = styled.div`
  color: ${tokens.colorGray7};
  font-size: ${tokens.fontSize2};
  font-family: ${tokens.fontFamilyRegular};
  line-height: ${tokens.lineHeight3};
  margin-top: 24px;

  img {
    margin-bottom: 8px;
  }

  p {
    margin: 0 0 2px;
  }
`
