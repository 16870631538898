const getStratusToken = async (): Promise<string> => {
  if (window.Shell && window.Shell.v1.authProvider) {
    return window.Shell.v1.authProvider.getAccessToken()
  } else if (window.Portal && window.Portal.auth) {
    return window.Portal.auth.getAccessToken()
  } else {
    const cookieToken = await cookieStore.get('stratus-access-token')
    return cookieToken?.value || ''
  }
}

export const AuthProvider = {
  getAccessToken: getStratusToken
}

export default getStratusToken
