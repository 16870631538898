import Images from '@/assets/images'
import useGetText from '@/hooks/useGetText'
import { Order } from '@/types/OrderList'
import React from 'react'
import { StyledOrderHeader } from './styles'
import { OrderHelper } from '@/helpers/transformOrderResponseHelper'

const OrderHeader: React.FC<{ order: Order }> = ({ order }) => {
  const getText = useGetText('application.orderList')

  return (
    <StyledOrderHeader>
      <img className="product-logo" src={Images.hpAllinPLan} />
      <p data-testid="order-placed-at">
        {getText('orderPlacedAt')}
        {OrderHelper.getFormatDate(new Date(order.orderPlacedAt))}
      </p>
      <p data-testid="order-number">
        {getText('orderNumber')}
        {order?.orderNumber}
      </p>
    </StyledOrderHeader>
  )
}

export default OrderHeader
