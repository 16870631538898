import { trace } from '@opentelemetry/api'

const sendOpenTelemetryEvent = (message: string) => {
  const span = trace
    .getTracer('order-management')
    .startSpan('order-management', {
      attributes: {
        'workflow.name': 'monetization/order-management.error',
        error: true,
        'error.message': message
      }
    })
  span.end()
}

export default sendOpenTelemetryEvent
